<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="main-wrapper-large">
		<PreOrderForm
			:pre-order-general-info="generalInfo"
			:pre-order-campaign-sku="campaignSku"
			:pre-order-product-price-display="productPriceDisplay"
			:pre-order-schedule-info="scheduleInfo"
			:pre-order-limit="limit"
			:pre-order-delivery-shipping-info="deliveryShippingInfo"
			:pre-order-label="label"
			:pre-order-campaign-info="campaignInfo"
			:pre-order-terms-and-conditions="termsAndConditions"
			:pre-order-grab-and-go="grabAndGo"
			:pre-order-custom-button="customButton"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PreOrderForm from '@/components/PreOrderForm.vue';

import { transformedPreOrderToMultipleSectionFromAPI } from '../assets/js/transform/preorders';

export default {
	name: 'PreOrderCreate',
	components: {
		PreOrderForm,
	},
	data() {
		return {
			isLoading: true,
			generalInfo: {},
			campaignSku: {},
			productPriceDisplay: {},
			scheduleInfo: {},
			limit: {},
			deliveryShippingInfo: {},
			label: {},
			campaignInfo: {},
			termsAndConditions: {},
			grabAndGo: {},
			customButton: {},
		};
	},
	computed: {
		...mapState('preorders', {
			edit: 'edit',
		}),
	},
	async created() {
		const { ref: preOrderId = null } = this.$route.query;

		if (preOrderId) {
			await this.getPreOrder(preOrderId);
			this.extractData();
		} else {
			this.isLoading = false;
		}
	},
	methods: {
		...mapActions({
			getPreOrder: 'preorders/getPreOrder',
		}),
		extractData() {
			if (this.edit && this.edit.data) {
				const preOrder = this.edit.data;

				const {
					generalInfo,
					campaignSku,
					productPriceDisplay,
					scheduleInfo,
					limit,
					deliveryShippingInfo,
					label,
					campaignInfo,
					termsAndConditions,
					grabAndGo,
					customButton,
				} = transformedPreOrderToMultipleSectionFromAPI(preOrder) || {};

				this.generalInfo = generalInfo;
				this.campaignSku = campaignSku;
				this.productPriceDisplay = productPriceDisplay;
				this.scheduleInfo = scheduleInfo;
				this.limit = limit;
				this.deliveryShippingInfo = deliveryShippingInfo;
				this.label = label;
				this.campaignInfo = campaignInfo;
				this.termsAndConditions = termsAndConditions;
				this.grabAndGo = grabAndGo;
				this.customButton = customButton;
			}

			this.isLoading = false;
		},
	},
};
</script>
